// Color palette from Happy Hues (https://www.happyhues.co/palettes/4)
export const DARK_THEME = 'dark';
export default {
  name: DARK_THEME,
  colors: {
    fg: {
      base: '#fffffe',
      dark: '#94a1b2',
    },
    bg: {
      base: '#16161a',
      dark: '#242629',
    },
    primary: {
      light: '#b299ff',
      base: '#7f5af0',
    },
    black: '#010101',
    tertiary: {
      light: '#63cda1',
      base: '#2cb67d',
    },
  },
};
