// Color palette from Happy Hues (https://www.happyhues.co/palettes/6)
export const LIGHT_THEME = 'light';
export default {
  name: 'light',
  colors: {
    fg: {
      base: '#2b2c34',
    },
    bg: {
      base: '#fffffe',
      dark: '#d1d1e9',
    },
    primary: {
      light: '#6246ea',
      base: '#6246ea',
    },
    black: '#2b2c34',
    tertiary: {
      light: '#ea8585',
      base: '#e45858',
    },
  },
};
