import * as React from 'react';
import { withTheme } from 'styled-components';

const Y = ({ theme, ...props }) => (
  <svg width={31} height={47} viewBox="0 0 31 47" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.11 10.846s.209.954 1.053.118c.843-.836 2.776-5.548 5.097-4.95 2.32.599 1.474 3.798 1.454 6.352-.019 2.396-.731 8.776 5.664 11.704 6.395 2.929 9.976-4.223 9.976-4.223s.682-1.713-1.322-.968c-2.004.746-6.096 1.348-7.71-.722-1.612-2.071-1.757-5.245-1.79-7.433C12.5 8.536 13.953 1.917 11.187.6 7.172-1.31-1.046 1.142.11 10.846zm10.402 22s.8-.65.663.408c-.137 1.057-1.113 6.292-1.225 7.314-.11 1.022.605.7.806.642.201-.058 3.86-2.165 6.569-5.922 2.685-3.724 6.406-8.427 7.571-16.296.835-5.642.659-16.9.618-18.442-.01-.364.168-.412.432-.44.862-.093 2.375-.15 4.224.089.536.07.629.146.656.598.074 1.219.422 8.344-.14 14.875-1.671 19.407-12.299 27.037-19.104 30.568-2.874 1.49-5.115.64-5.493-1.263-.52-2.622-.858-5.93 4.423-12.13z"
      fill={theme.colors.primary.base}
    />
  </svg>
);

export default withTheme(Y);
